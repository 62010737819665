<template>
  <!-- blogs -->
  <b-row class="blog-list-wrapper">
    <h2 class="ml-2 mb-2">Son Eklenenler</h2>
    <br />
    <b-col v-for="blog in blogList" :key="blog.img" md="12">
      <b-card class="fontCard" tag="article" no-body>
        <b-row>
          <b-col md="5">
            <b-link :to="{ name: 'second-page', params: { id: blog.id } }">
              <b-img
                :src="blog.img"
                :alt="blog.img.slice(5)"
                class="card-img-top"
                style="width: 100%; height: 100%; object-fit: cover"
              />
            </b-link>
          </b-col>

          <b-col md="7">
            <b-card-body>
              <b-card-title>
                <b-link
                  :to="{ name: 'second-page', params: { id: blog.id } }"
                  class="blog-title-truncate text-body-heading"
                >
                  <h2 class="display-5">{{ blog.title }}</h2>
                </b-link>
              </b-card-title>
              <div class="altkisim">
                <b-media no-body>
                  <b-media-aside vertical-align="center" class="mr-50">
                    <b-avatar
                      href="javascript:void(0)"
                      size="24"
                      :src="blog.avatar"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <small class="text-muted mr-50">by</small>
                    <small>
                      <b-link class="text-body">{{ blog.userFullName }}</b-link>
                    </small>
                    <span class="text-muted ml-75 mr-50">|</span>
                    <small class="text-muted">{{ blog.blogPosted }}</small>
                  </b-media-body>
                </b-media>
                <div class="my-1 py-25">
                  <b-link v-for="(tag, index) in blog.tags" :key="index">
                    <b-badge pill class="mr-75" :variant="tagsColor(tag)">
                      {{ tag }}
                    </b-badge>
                  </b-link>
                </div>
                <b-card-text class="blog-content-truncate">
                  {{ blog.excerpt }}
                </b-card-text>
                <hr />
                <div class="d-flex justify-content-between">
                  <b-link
                    :to="{ name: 'second-page', params: { id: blog.id } }"
                    class="font-weight-bold ml-auto"
                    style="color: orange"
                  >
                    Daha fazla...
                  </b-link>
                </div>
              </div>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BImg,
    BPagination,
  },
  data() {
    return {
      search_query: "",
      perPage: 1,
      rows: 140,
      blogList: [
        {
          id: 1,
          img: require("@/assets/images/allPost/welcome.gif"),
          title: "LifeSakarya'ya Hoş Geldiniz!",
          avatar: require("@/assets/images/avatars/rabiaa.jpeg"),
          userFullName: "Rabia || Çağla",
          blogPosted: " 14.11.2023",
          tags: ["Trend"],
          tabs: "trend",
          excerpt:
            "Sakarya'nın kalbinde bir soluklanma noktasına hoş geldiniz. LifeSakarya, bu şehrin nefes kesen güzelliklerini, tarih kokan sokaklarını ve keşfedilmeyi bekleyen sırlarını keşfetmek için bir rehberdir. Bizler, bu eşsiz coğrafyanın içinde kaybolmaya hazır, maceraperest ruhları bir araya getiren bir topluluğuz.",
        },
        {
          id: 16,
          img: require("@/assets/images/allPost/poyrazlar.jpg"),
          title: "Doğa Harikası Poyrazlar Gölü",
          avatar: require("@/assets/images/avatars/caglaa.jpg"),
          userFullName: "Çağla Düdükçü",
          blogPosted: "17.01.2024",
          tags: ["Sakarya"],
          tabs: "sakarya",
          excerpt:
            "Poyrazlar Gölü, Sakarya'nın en güzel doğal güzelliklerinden biri olarak bilinir. Bu göl, doğal koruma alanı statüsünde olup, ziyaretçilerine görsel bir şölen sunuyor. Şimdi sizlere bu muhteşem göl hakkında samimi bir yazı sunmak istiyorum. Göle adım attığınız anda sizi büyüleyen bir manzara ile karşılaşırsınız",
        },
        {
          id: 15,
          img: require("@/assets/images/allPost/acarlar.jpg"),
          title: "Sakarya'nın İncisi: Acarlar Langozu",
          avatar: require("@/assets/images/avatars/caglaa.jpg"),
          userFullName: "Çağla Düdükçü",
          blogPosted: "15.01.2024",
          tags: ["Sakarya"],
          tabs: "sakarya",
          excerpt:
            "Sakarya'nın incisi olarak bilinen Acarlar Longozu, Karasu ilçesinde bulunan muazzam bir doğa harikasıdır. Bu doğal güzellik, Türkiye'nin en büyük tatlı su deltalarındandır. Acarlar Longozu, sulak alan ekosistemiyle ünlüdür ve birçok nadir bitki ve kuş türüne ev sahipliği yapar. Kayık turlarıyla bu eşsiz bölgeyi keşfetmek, sessiz sular üzerinde yol alırken çevresindeki doğanın güzelliklerini gözlemlemek anlamlı bir deneyim.",
        },
        {
          id: 14,
          img: require("@/assets/images/allPost/four.jpg"),
          title: "Sakarya Aktivite Mekanı: Four Reels",
          avatar: require("@/assets/images/avatars/caglaa.jpg"),
          userFullName: "Çağla Düdükçü",
          blogPosted: "13.01.2024",
          tags: ["Trend"],
          tabs: "trend",
          excerpt:
            "Sakarya Four Reels, Sakarya ilinin Serdivan ilçesinde yer alan ve her türlü etkinlik için mükemmel bir mekan olan bir eğlence merkezidir. Burası, eğlence dünyasının en çarpıcı ve yaratıcı etkinliklerine ev sahipliği yapmaktadır.Her yaş gruplarından insanlara hitap eden birçok aktivite sunmaktadır.   ",
        },
        {
          id: 13,
          img: require("@/assets/images/allPost/ormanpark.jpg"),
          title: "SAKARYA: ORMANPARK",
          avatar: require("@/assets/images/avatars/caglaa.jpg"),
          userFullName: "Çağla Düdükçü",
          blogPosted: "10.01.2024",
          tags: ["Sakarya"],
          tabs: "sakarya",
          excerpt:
            "Doğayla iç içe olmayı sevenler için Sakarya'nın en güzel mekanlarından biri olan Orman Parkta, geçtiğimiz hafta sonu arkadaşlarımla birlikte burayı ziyaret etme imkanı buldum ve gerçekten unutulmaz anılarım oldu.Sabah erken saatlerde yola çıktık ve Orman Park'a ulaşmak için 30 dakikalık bir yolculuk yaptık   ",
        },
        {
          id: 12,
          img: require("@/assets/images/allPost/tarakli.jpg"),
          title: "SAKARYA TARAKLI: SAKİN ŞEHİR",
          avatar: require("@/assets/images/avatars/caglaa.jpg"),
          userFullName: "Çağla Düdükçü",
          blogPosted: "05.01.2024",
          tags: ["Sakarya"],
          tabs: "sakarya",
          excerpt:
            "Taraklı'ya adım attığınızda, sizi alacağınız ilk şey yemyeşil özellikler olacak. Şehirden ayrıldığınızda, Taraklı'nın tüm stresinizi ve sorunlarınızı unutamayacağınız huzurlu bir yer hissedebilirsiniz. Burada doğa yürüyüşleri yapılabilir, şehirdeki havayı çözebilir ve eşsiz manzaralar eşliğinde dinginliğin keyfini çıkarabilirsiniz.   ",
        },
      ],
    };
  },
  created() {},
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === "Kampüs") return "light-info";
      if (tag === "Trend") return "light-warning";
      if (tag === "Sakarya") return "light-success";

      return "light-primary";
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Dancing+Script&family=Prompt:wght@200&display=swap");

.fontCard {
  font-family: "Prompt", sans-serif;
}
.color {
  color: black !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
